import React from 'react';
import { StyledContactUs } from './styles';
import { Grid2 } from '@mui/material';
import { contacts } from './contactContent';
import { ContentWrapper } from '../home/homeContent';
import Content from '../../components/content/content';
import ContactForm from './contactForm';

const ContactUs: React.FC = (): React.ReactElement => {
  return (
    <StyledContactUs>
      <Grid2 container rowSpacing={3} spacing={2} justifyContent='center'>
        {contacts.map((content: ContentWrapper, idx: number) => {
          return (
            <Grid2 size={{ xs: 12, sm: 10, md: 10 }} key={idx}>
              <Content logo={content.logo} content={content.content} />
            </Grid2>
          );
        })}
        {/* <ContactForm /> */}
      </Grid2>
    </StyledContactUs>
  );
};

export default ContactUs;
