export type ReviewContent = {
  name: string;
  content: string;
  stars: number;
};

export const reviews: ReviewContent[] = [
  {
    name: 'Therapy Services for Children PLLC ',
    content:
      'Freedom Billing LLC is excellent. They are responsive, flexible, honest and fair. The owner, Melinda, does a great job training her employees and they are all competent and kind. The billing goes out fast and I appreciate that. I have a small OT clinic and have trusted Freedom Billing with my insurance billing and customer invoices since 2012.',
    stars: 5,
  },
  {
    name: 'Mystic Path Counseling',
    content:
      'Very fast, efficient, streamlined service. They are also very responsive to my questions and make things easy for me. I appreciate Freedom Billing immensely.',
    stars: 5,
  },
  {
    name: 'Concerned Citizens',
    content: 'The fact that we are finally getting some billable hours paid through Medicare and insurance.',
    stars: 5,
  },
  {
    name: 'Rebecca Yarmuth Therapy',
    content: 'Personal attention and professionalism.',
    stars: 5,
  },
  {
    name: 'Eudaimonia Health & Wellness PLLC',
    content: "I get excellent service for the cost, they've always been friendly and very knowledgeable!",
    stars: 4,
  },
];
