import React from 'react';
import { StyledHome } from './styles';
import { Grid2 } from '@mui/material';
import Content from '../../components/content/content';
import { ContentWrapper, summaries } from './homeContent';

const Home: React.FC = (): React.ReactElement => {
  return (
    <StyledHome>
      <Grid2 container rowSpacing={4} justifyContent='center'>
        <Grid2
          size={{ xs: 11, sm: 10, md: 10 }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          className='title'>
          Full Service Medical Billing and Insurance Credentialling
        </Grid2>
        {summaries.map((content: ContentWrapper, idx: number) => {
          return (
            <Grid2 size={{ xs: 12, sm: 10, md: 10 }} key={idx}>
              <Content logo={content.logo} content={content.content} linkPath={content.linkPath} />
            </Grid2>
          );
        })}
      </Grid2>
    </StyledHome>
  );
};

export default Home;
