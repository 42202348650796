import { Icon } from '@mui/material';
import { ContentWrapper } from '../home/homeContent';
import { AccessTime, Email, PhoneIphone, RequestQuote } from '@mui/icons-material';

export const contacts: ContentWrapper[] = [
  {
    logo: <Icon component={PhoneIphone} className='icon' />,
    content: (
      <div className='paddedContent'>
        We can be reached by text or call at (360) 789 - 2332. We can also be reached via fax at (360) 918 - 8274.
      </div>
    ),
  },
  {
    logo: <Icon component={Email} className='icon' />,
    content: (
      <div className='paddedContent'>
        We can be reached directly at melinda@freedombillingllc.com. We respond to emails during regular business hours
        and typically respond within 24 hours, when the office is open.
      </div>
    ),
  },
  {
    logo: <Icon component={AccessTime} className='icon' />,
    content: (
      <div className='paddedConetnt'>
        Weekly hours (MDT/MST):
        <ul>
          <li>Monday: 9AM - 5PM</li>
          <li>Tuesday: 9AM - 5PM</li>
          <li>Wednesday: 9AM - 5PM</li>
          <li>Thursday: 9AM - 5PM</li>
          <li>Friday - Sunday: Closed</li>
        </ul>
      </div>
    ),
  },
  {
    logo: <Icon component={RequestQuote} className='icon' />,
    content: (
      <div className='paddedContent'>
        Interested in a quote? Use the following form to request a quote or ask any questions you may have. For accurate
        quotes, please include the following information:
        <ul>
          <li>Name of your business</li>
          <li>Owner's name</li>
          <li>Number of patients seen per week</li>
          <li>Services provided by the business</li>
          <li>Individual or group practice</li>
        </ul>
      </div>
    ),
  },
];
