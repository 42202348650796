import React from 'react';
import { StyledHeader } from './styles';
import { Button, Grid2, Icon, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/Freedom Billing Logo.svg';
import { Menu as MenuIcon } from '@mui/icons-material';

const Header: React.FC = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledHeader>
      <Grid2 container spacing={2} minHeight={64}>
        <Grid2 size={{ xs: 4, md: 2 }} display='flex' justifyContent='center' alignItems='center'>
          <img src={Logo} alt='Freedom Billing LLC Logo' className='logo' />
        </Grid2>
        <Grid2 size={{ xs: 5, md: 3 }} display='flex' justifyContent='flex-start' alignItems='center' className='title'>
          Freedom Billing LLC
        </Grid2>
        <Grid2
          container
          spacing={3}
          size={{ md: 7 }}
          display='flex'
          justifyContent='center'
          alignItems='center'
          className='hiddenSmall'>
          <Grid2 size='auto'>
            <NavLink className='link' to='/'>
              Home
            </NavLink>
          </Grid2>
          <Grid2 size='auto'>
            <NavLink className='link' to='/services'>
              Services
            </NavLink>
          </Grid2>
          <Grid2 size='auto'>
            <NavLink className='link' to='/pricing'>
              Pricing
            </NavLink>
          </Grid2>
          <Grid2 size='auto'>
            <NavLink className='link' to='/contact-us'>
              Contact Us
            </NavLink>
          </Grid2>
          <Grid2 size='auto'>
            <NavLink className='link' to='/faq'>
              FAQ
            </NavLink>
          </Grid2>
          <Grid2 size='auto'>
            <NavLink className='link' to='/about'>
              About
            </NavLink>
          </Grid2>
          <Grid2 size='auto'>
            <NavLink className='link' to='/reviews'>
              Reviews
            </NavLink>
          </Grid2>
        </Grid2>
        <Grid2
          container
          spacing={3}
          size={3}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          className='hideLarge'>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <Icon component={MenuIcon} className='icon' />
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/'>
                Home
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/services'>
                Services
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/pricing'>
                Pricing
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/contact-us'>
                Contact Us
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/faq'>
                FAQ
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/about'>
                About
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <NavLink className='link' to='/reviews'>
                Reviews
              </NavLink>
            </MenuItem>
          </Menu>
        </Grid2>
      </Grid2>
    </StyledHeader>
  );
};

export default Header;
