import React from 'react';
import { StyledFaq } from './styles';
import { Grid2 } from '@mui/material';
import { FaqContent, Faqs } from './faqContent';

const FAQ: React.FC = (): React.ReactElement => {
  return (
    <StyledFaq>
      <Grid2 container rowSpacing={4} justifyContent='center'>
        <Grid2 container size={{ xs: 12, sm: 10, md: 10 }}>
          {Faqs.map((faq: FaqContent, idx: number) => {
            return (
              <Grid2 container rowSpacing={1} size={12} key={idx} className='faq content'>
                <Grid2 size={12} className='bold'>
                  {faq.question}
                </Grid2>
                <Grid2 size={1}></Grid2>
                <Grid2 size={11}>{faq.answer}</Grid2>
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>
    </StyledFaq>
  );
};

export default FAQ;
