import React from 'react';
import Header from '../../components/header/header';
import { StyledApp } from './styles';
import { Grid2 } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from '../home/home';
import About from '../about/about';
import Services from '../services/services';
import Pricing from '../pricing/pricing';
import ContactUs from '../contact/contact';
import FAQ from '../faq/faq';
import Reviews from '../reviews/reviews';
import Footer from '../../components/footer/footer';

const App: React.FC = (): React.ReactElement => {
  return (
    <StyledApp>
      <BrowserRouter>
        <Grid2 container rowSpacing={2} justifyContent='center'>
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about' element={<About />} />
              <Route path='/services' element={<Services />} />
              <Route path='/pricing' element={<Pricing />} />
              <Route path='/contact-us' element={<ContactUs />} />
              <Route path='/faq' element={<FAQ />} />
              <Route path='/reviews' element={<Reviews />} />
              <Route path='*' element={<Navigate to='/' replace={true} />} />
            </Routes>
            <Footer />
          </Grid2>
        </Grid2>
      </BrowserRouter>
    </StyledApp>
  );
};

export default App;
