import React from 'react';
import { StyledServices } from './styles';
import { Grid2 } from '@mui/material';
import { services } from './serviceContent';
import { ContentWrapper } from '../home/homeContent';
import Content from '../../components/content/content';

const Services: React.FC = (): React.ReactElement => {
  return (
    <StyledServices>
      <Grid2 container rowSpacing={2} spacing={2} justifyContent='center'>
        <Grid2 size={{ xs: 11, sm: 10, md: 10 }}>
          Freedom Billing LLC is a full service billing company providing services to a variety of disciplines. Some of
          the disciplines we specialize in are: billing for school district/ESD Birth to Three programs, audiology, and
          mental health programs. We also provide billing services for many independent practices that provide
          occupational, physical, speech therapy or mental health counseling. We are experts at communicating with
          insurances and making sure you are getting the correct reimbursement in a timely manner.
          <div>
            <br />
            Our core values:
            <ul>
              <li>Providing excellent, timely, and accurate billing and services to our providers.</li>
              <li>Educating and helping providers grow their individual practices.</li>
              <li>Providing financial management for our provider's success.</li>
            </ul>
          </div>
        </Grid2>
        {services.map((content: ContentWrapper, idx: number) => {
          return (
            <Grid2 size={{ xs: 12, sm: 10, md: 10 }} key={idx}>
              <Content logo={content.logo} content={content.content} />
            </Grid2>
          );
        })}
      </Grid2>
    </StyledServices>
  );
};

export default Services;
