import styled from 'styled-components';

export const StyledAbout = styled.div`
  margin-top: 16px;
  min-height: calc(100vh - 220px);

  .about {
    background-color: #d3fafb;
    background: radial-gradient(circle, rgba(211, 250, 251, 1) 0%, rgba(129, 240, 242, 1) 82%);
    color: black;
    border-radius: 8px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.4), 0 10px 24px 0 rgba(0, 0, 0, 0.4);
    padding: 16px;
    margin-top: 4px;
    font-size: 16px;
    text-align: justify;
  }

  .logo {
    height: 364px;
  }
`;
