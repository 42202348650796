import styled from 'styled-components';

export const StyledPricing = styled.div`
  margin-top: 16px;
  min-height: calc(100vh - 220px);
  text-align: justify;
  .paddedContent {
    padding-right: 16px;
  }
`;
