export type FaqContent = {
  question: string;
  answer: string;
};

export const Faqs: FaqContent[] = [
  {
    question: 'Do I need to use all of your services in order to become a client?',
    answer:
      'We do our best to tailor our services to your needs. There are some processes that we must do in order to provide the best customer service, but we are very flexible.',
  },
  {
    question:
      'Will you be the office liaison with my patients so they understand the billing process and their insurance?',
    answer:
      'Yes we offer full service intake processes including talking to patients and their insurances to gather all information needed to complete the billing process.',
  },
  {
    question: 'How do I communicate patient information and billing to your office?',
    answer: 'We use fax, email, and even standard mail to communicate.',
  },
  {
    question: 'Are your fax and email Hipaa compliant?',
    answer:
      'Yes.  We use Microsoft Office 365 Business, Premium Edition, for email and cloud services, and Nextiva for our fax.',
  },
  {
    question: 'Do I have to sign a contract?',
    answer:
      'No, we do not have you sign a contract.  If you want to end our billing relationship, all we ask is that you give us enough time to close out your pending claims so you are reimbursed everything you are due.',
  },
  {
    question: 'Can I refer my friend to you?',
    answer:
      'Yes! referrals are the highest form of praise to a small business. Send them our way and we will be happy to give them a quote.',
  },
];
