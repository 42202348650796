import styled from 'styled-components';
import background from '../../assets/Freedom Billing Website Background - 4.png';

export const StyledApp = styled.div`
  min-height: 100vh;
  color: #ffffff;
  background-image: url('${background}');
  background-size: cover;
  font-family: 'Roboto';
`;
