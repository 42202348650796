import styled from 'styled-components';

export const StyledContent = styled.div`
  background-color: #d3fafb;
  background: radial-gradient(circle, rgba(211, 250, 251, 1) 0%, rgba(129, 240, 242, 1) 82%);
  color: black;
  border-radius: 8px;

  .contentShadow {
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.4), 0 10px 24px 0 rgba(0, 0, 0, 0.4);
    border-radius: 8px;
  }
  .icon {
    font-size: 80px;
    color: #046171;
  }
  .button {
    font-size: 12px;
    color: #ffffff;
    padding: 8px;
    margin: 8px;
    background-color: #046171;
  }
  .content {
    padding: 16px;
    margin-top: 4px;
    font-size: 16px;
    text-align: justify;
  }
`;
