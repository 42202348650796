import { Assignment, AttachMoney, Email, Person, QuestionMark, Star } from '@mui/icons-material';
import { Icon } from '@mui/material';

export type ContentWrapper = {
  logo: JSX.Element;
  content: string | JSX.Element;
  linkPath?: string;
};

export const summaries: ContentWrapper[] = [
  {
    logo: <Icon component={Assignment} className='icon' />,
    content:
      'From full service medical billing, account management, and patient scheduling, our dedicated team can satisfy your business needs.',
    linkPath: '/services',
  },
  {
    logo: <Icon component={AttachMoney} className='icon' />,
    content: 'We are committed to providing the highest quality service at an affordable price.',
    linkPath: '/pricing',
  },
  {
    logo: <Icon component={Email} className='icon' />,
    content: 'Our highly responsive team is eager to respond to any questions or concerns you and your team may have.',
    linkPath: '/contact-us',
  },
  {
    logo: <Icon component={QuestionMark} className='icon' />,
    content: 'The most common questions and answers shared by others looking for billing and credentialling services.',
    linkPath: '/faq',
  },
  {
    logo: <Icon component={Person} className='icon' />,
    content:
      'Founded in 1995 by Owner and CEO Melinda Carruth, Freedom Billing LLC has grown from a small local business to a nation wide billing and credentialling company.',
    linkPath: '/about',
  },
  {
    logo: <Icon component={Star} className='icon' />,
    content:
      'Find out how our clients feel after having partnered with Freedom Billing LLC to improve their ability to provide great patient care.',
    linkPath: '/reviews',
  },
];
