import React from 'react';
import { StyledPricing } from './styles';
import { Grid2 } from '@mui/material';
import { pricings } from './pricingContent';
import { ContentWrapper } from '../home/homeContent';
import Content from '../../components/content/content';

const Pricing: React.FC = (): React.ReactElement => {
  return (
    <StyledPricing>
      <Grid2 container rowSpacing={3} spacing={2} justifyContent='center'>
        {pricings.map((content: ContentWrapper, idx: number) => {
          return (
            <Grid2 size={{ xs: 12, sm: 10, md: 10 }} key={idx}>
              <Content logo={content.logo} content={content.content} />
            </Grid2>
          );
        })}
      </Grid2>
    </StyledPricing>
  );
};

export default Pricing;
