import React from 'react';
import { Button, Grid2 } from '@mui/material';
import { ContentWrapper } from '../../pages/home/homeContent';
import { StyledContent } from './styles';
import { useNavigate } from 'react-router-dom';

const Content: React.FC<ContentWrapper> = ({ logo, content, linkPath }: ContentWrapper): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <StyledContent>
      <Grid2 container spacing={window.screen.width < 601 ? 0 : 2} className='contentShadow'>
        <Grid2 size={{ xs: 3, sm: 3, md: 2 }} display='flex' justifyContent='center' alignItems='center'>
          {logo}
        </Grid2>
        <Grid2 size={linkPath ? { xs: 9, sm: 9, md: 8 } : { xs: 9, sm: 9, md: 10 }} className='content'>
          {content}
        </Grid2>
        {linkPath && (
          <Grid2 size={{ xs: 12, sm: 12, md: 2 }} display='flex' justifyContent='flex-end' alignItems='flex-end'>
            <Button onClick={() => navigate(linkPath)} className='button'>
              Learn more
            </Button>
          </Grid2>
        )}
      </Grid2>
    </StyledContent>
  );
};

export default Content;
