import { Icon } from '@mui/material';
import { ContentWrapper } from '../home/homeContent';
import { AccessTime, CalendarMonth, Percent } from '@mui/icons-material';

export const pricings: ContentWrapper[] = [
  {
    logo: <Icon component={AccessTime} className='icon' />,
    content: (
      <div className='paddedContent'>
        We offer hourly pricing based on the total number of hours it takes our team to manage your account. The exact
        hourly cost varies depending on many factors, including case load size and the number of providers on your
        staff. Hourly pricing works well with clients who have inconsistent case loads.
      </div>
    ),
  },
  {
    logo: <Icon component={Percent} className='icon' />,
    content: (
      <div className='paddedContent'>
        We offer a percentage pricing model based on the total value of the paid claims during a given month. Similar to
        other pricing models, the exact percentage dependes on various factors. Percentage pricing works well with
        clients who have consistent case loads.
      </div>
    ),
  },
  {
    logo: <Icon component={CalendarMonth} className='icon' />,
    content: (
      <div className='paddedContent'>
        Reserved for special cases, we offer a fixed monthly pricing model. Typically, we reserve this pricing model for
        clients who have relatively small and very consistent case loads.
      </div>
    ),
  },
];
