import React from 'react';
import { StyledReviews } from './styles';
import { Grid2, Icon } from '@mui/material';
import { ReviewContent, reviews } from './reviewContent';
import { Person, Star } from '@mui/icons-material';

const Reviews: React.FC = (): React.ReactElement => {
  const getStars = (num: number) => {
    const stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<Icon component={Star} className='icon' />);
    }

    return stars;
  };
  return (
    <StyledReviews>
      <Grid2 container rowSpacing={4} spacing={2} justifyContent='center'>
        <Grid2 container size={{ xs: 12, sm: 10, md: 10 }}>
          {reviews.map((review: ReviewContent, idx: number) => {
            return (
              <Grid2 container rowSpacing={1} size={{ xs: 12, sm: 12, md: 12 }} className='review ' key={idx}>
                <Grid2 size={{ xs: 2, sm: 2, md: 2 }} display='flex' justifyContent='center' alignItems='center'>
                  <Icon component={Person} className='icon' />
                </Grid2>
                <Grid2
                  size={{ xs: 6, sm: 6, md: 6 }}
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  className='name'>
                  {review.name}
                </Grid2>
                <Grid2 size={{ xs: 4, sm: 4, md: 4 }} display='flex' justifyContent='flex-start' alignItems='center'>
                  {getStars(review.stars)}
                </Grid2>
                <Grid2 size={12}>{review.content}</Grid2>
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>
    </StyledReviews>
  );
};

export default Reviews;
