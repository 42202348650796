import styled from 'styled-components';

export const StyledHome = styled.div`
  margin-top: 16px;
  min-height: calc(100vh - 220px);
  .title {
    font-size: 30px;
    padding: 8px;
  }
  text-align: justify;
`;
