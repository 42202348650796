import { Icon } from '@mui/material';
import { ContentWrapper } from '../home/homeContent';
import { CalendarMonth, ContactPage, Paid } from '@mui/icons-material';

export const services: ContentWrapper[] = [
  {
    logo: <Icon component={Paid} className='icon' />,
    content: (
      <div className='paddedContent'>
        We offer full service medical billing and account receivable management. We are committed to providing timely
        and accurate billing, persistent claim follow up, and ensuring claims are paid properly. Some of our billing
        services include, but are not limited to: <br />
        <ul>
          <li>Timely claim billing</li>
          <li>Remittance and payment posting</li>
          <li>Patient Statements</li>
          <li>Soft collections</li>
          <li>Overdue claim follow-up</li>
          <li>Pre-authorizations</li>
          <li>Visit limit tracking</li>
          <li>Insurance benefit checks</li>
          <li>Patient deductible and copay payments</li>
        </ul>
      </div>
    ),
  },
  {
    logo: <Icon component={ContactPage} className='icon' />,
    content: (
      <div className='paddedContent'>
        We offer insurance credentialling to aid in receiving timely and consistent payments. We have experience with
        all major insurance companies as well as numerous other agencies.
      </div>
    ),
  },
  {
    logo: <Icon component={CalendarMonth} className='icon' />,
    content: (
      <div className='paddedContent'>
        We offer new patient intake services and manage wait lists to help your practice always be ready to take on new
        patients. We gather demographic information, insurance information, provide insurance benefit checks, and then
        give you the whole package so you can schedule your new patients with ease. We contact patients that have
        insurances you do not do business with and refer them elsewhere. This service frees up your time from making
        multiple phone calls and managing an ever changing wait list.
      </div>
    ),
  },
];
