import styled from 'styled-components';

export const StyledHeader = styled.div`
  @media screen and (min-width: 901px) {
    height: 124px;
    margin-top: 16px;
    border-bottom: #cdedfd 2px solid;
    padding: 4px;

    .hideLarge {
      display: none;
    }
    .logo {
      height: 124px;
      filter: drop-shadow(6px 6px 6px #000000);
    }
    .title {
      font-size: 32px;
    }
    .link {
      font-size: 20px;
      color: #ffffff;
    }
    .link:hover,
    .active {
      color: #81f7e5;
    }
  }
  @media screen and (max-width: 900px) {
    height: 100px;
    margin-top: 16px;
    border-bottom: #cdedfd 2px solid;
    padding: 4px;

    .hiddenSmall {
      display: none;
    }
    .logo {
      height: 98px;
      filter: drop-shadow(6px 6px 6px #000000);
    }
    .title {
      font-size: 28px;
    }
    .link {
      font-size: 20px;
      color: #ffffff;
    }
    .link:hover,
    .active {
      color: #81f7e5;
    }
    .icon {
      font-size: 48px;
      color: #ffffff;
    }
  }
`;
