import React from 'react';
import { StyledFooter } from './styles';
import { Grid2, Icon } from '@mui/material';
import { Copyright } from '@mui/icons-material';

const Footer: React.FC = (): React.ReactElement => {
  return (
    <StyledFooter>
      <Grid2 container spacing={2}>
        <Grid2 size={12} display='flex' justifyContent='center' alignItems='center'>
          Est. 1995 &nbsp; | &nbsp;
          <Icon component={Copyright} /> &nbsp; 2024 All rights reserved.
        </Grid2>
      </Grid2>
    </StyledFooter>
  );
};

export default Footer;
