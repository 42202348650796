import React from 'react';
import { StyledAbout } from './styles';
import { Grid2 } from '@mui/material';
import Logo from '../../assets/Freedom Billing Logo.svg';

const About: React.FC = (): React.ReactElement => {
  return (
    <StyledAbout>
      <Grid2 container rowSpacing={3} spacing={2} justifyContent='center'>
        <Grid2 container size={{ xs: 12, sm: 10, md: 10 }}>
          <Grid2 size={12} display='flex' justifyContent='center' alignItems='center'>
            <img src={Logo} alt='Freedom Billing LLC Logo' className='logo' />
          </Grid2>
          <Grid2 size={12} className='about'>
            Founded in 1995 by Owner and CEO, Melinda Carruth, Freedom Billing LLC has grown from a small business
            working at the dining room table to a company with several employees. Still operated in a small business
            atmosphere, Freedom Billing works with all sizes of companies. No company is too small. Our goal is make
            sure you are reimbursed all of the money you are legally and ethically owed. We support our customers to
            help them achieve financial stability and success.
          </Grid2>
        </Grid2>
      </Grid2>
    </StyledAbout>
  );
};

export default About;
