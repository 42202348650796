import styled from 'styled-components';

export const StyledContactUs = styled.div`
  margin-top: 16px;
  min-height: calc(100vh - 220px);

  .form {
    background-color: #d3fafb;
    background: radial-gradient(circle, rgba(211, 250, 251, 1) 0%, rgba(129, 240, 242, 1) 82%);
    color: black;
    border-radius: 8px;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.4), 0 10px 24px 0 rgba(0, 0, 0, 0.4);
    padding: 16px;

    .title {
      font-weight: bold;
      font-size: 24px;
      color: #046171;
    }
  }

  .button {
    font-size: 12px;
    color: #ffffff;
    padding: 8px;
    background-color: #046171;
  }

  .paddedContent {
    padding-right: 16px;
  }
`;
